import React from 'react'
import './features.css'
import Pastor from '../../assets/Pastor.jpg.jpg'
const Features = () => {
  return (
    <div className='tvbf__features section__padding' id='features'>
      <div className='tvbf__features-heading'>
        <h1 className='gradient__text'>Our Pastor</h1>
      </div>
      <div className='tvbf__pastor-bio'>
      <div className='tvbf__features-text'>
        <p>James Earl Cray is the Lead Pastor of True Vine Bible Fellowship of Lompoc, California. He was born in Hollywood, Florida. A true athlete at heart,
          he lettered in four sports in high school-football, baasketball, baseball, and track and field. After earning a full athletic scholarship to Troy 
          University for football, Cray graduated with a bachelor's degree in political science and a master's in sports and fitness management. In 2016, he
          furthered his studies and received a Master of Divitnity degree from The Interdenominational Theological Center in Atlanta, as an answer to his calling
          to do the Lord's work and spread the gospel. Cray has been in ministry for nearly 15 years. During this time, he has served as Staff Pastor of the young adult, 
          college, and social justice ministries at The House of Hope Atlanta under the leadership of Senior Pastor Dr. E. Dewey Smith Jr. Prior to relocating to Califronia, 
          his most recent post was Senior Pastor of EMPACT Church, located in his hometown of Hazlehurst, Georgia.{"\n"}
        </p>
        <p>
          Outside his pastoral duties, Cray is the founder and chairman of A Generation, Reaching, Educating, And Teaching (G.R.E.A.T Inc.), a non-projit organization based in southeast
          Georgia. He is a three-time published author and owner of G.R.E.A.T Publishing, most recently releasing "It's a Great Day to #BeGreat: Teen Eition" devotional.
          Additionally, Cray is the founder of a faith-based apparel line, Great Dezignz, where he motivates and inspires people from all walks of life through ministry and fashion.
          Cray is a proud member of Phi Beta Sigma Fraternity, Inc. and has one daughter, Jordyn Elizabeth Cray.
        </p>
        </div>
        <div className='tvbf__pastor'>
          <img src={Pastor} />
        </div>
        </div>
      </div>
  )
}

export default Features