import React from 'react'
import './header.css'
import community from '../../assets/community.jpg'


const Header = () => {
  return (
    <div className='tvbf__header section__padding' id="home">
      <div className='tvbf__header-content'>
        <h1 className='gradient__text'>Every Connection Starts With One</h1>
        <p>
          True Vine is boldy proclaiming the Gospel, building up the body, and becoming more like Christ.
        </p>
        <div className='tvbf__header-content__input'>
          <input type="email" placeholder='Your Email Address' />
          <button type="button">Get Connected</button>
        </div>
        </div>
        <div className='tvbf__header-image'>
            <img src={community} alt='community' />
        </div>
      </div>
  )
}

export default Header