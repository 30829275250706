import React from 'react'
import './blog.css'

const Blog = () => {
  return (
    <div className='tvbf__blog section__padding' id='blog'>
      <h1>Calendar Coming Soon!</h1>
    </div>
  )
}

export default Blog