import React, {useState} from 'react'
import { RiMenu3Line, RiCloseLine } from 'react-icons/ri'
import './navbar.css'
import logo from '../../assets/Truevinelogoresize.png'

const Menu = () => (
  <>
  <p><a href='#home'>Home</a></p>
  <p><a href='#whattvbf'>TVBF</a></p>
  <p><a href='#features'>Pastor</a></p>
  <p><a href='#possibility'>Goals</a></p>
  <p><a href='#blog'>Events</a></p>
  </>
)

const Navbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);
  return (
    <div className='tvbf__navbar'>
      <div className='tvbf__navbar-links'>
        <div className='tvbf__navbar-links_logo'>
          {/* <img src={logo} alt='alt' /> */}
          <div className='tvbf__navbar-links_container'>
            <Menu />
          </div>
        </div>
      </div>
      <div className='tvbf__navbar-sign'>
        <p>Sign In</p>
        <button type="button">
          <a href='https://cash.app/$tvbflompoc'>Donate</a>
        </button>  
      </div>
      <div className='tvbf__navbar-menu'>
        {toggleMenu
          ? <RiCloseLine color='#fff' size={27} onClick={() => setToggleMenu(false)}/>
          : <RiMenu3Line color='#fff' size={27} onClick={() => setToggleMenu(true)}/>
        } 
        {toggleMenu && (
          <div className='tvbf__navbar-menu_container scale-up-center'>
            <div className='tvbf__navbar-menu_container-links'>
              <Menu />
              <div className='tvbf__navbar-menu_container-links-sign'>
                <p>Sign In</p>
                <button type="button">
                  <a href='https://cash.app/$tvbflompoc'>Donate</a>
                </button>  
              </div>
            </div>
          </div>
        )}
      </div>  
    </div>
  )
}

export default Navbar