import React from 'react'
import { Feature } from '../../components'
import './whattvbf.css'


const WhatTVBF = () => {
  return (
    <div className='tvbf__whattvbf section__margin' id='whattvbf'>
      <div className='tvbf__whattvbf-feature'>
        <Feature 
        title="What is True Vine Bible Fellowship"
        text="Through Evangelism, Discipleship, Fellowship, Vision, and Excellence, True Vine
        will be connecting spirtually, locally, cuturally, and globally through the gospel
        message and ministry." />
      </div>
      <div className='tvbf__whattvbf-heading'>
        <h1 className='gradient__text'>
          The True Vine Initiative
        </h1>
        <p>
          Our History
        </p>
        </div>
        <div className='tvbf__whattvbf-container'>
          <Feature 
          title='Violence'
          text='Gang Violence Prevention and Domestic Violence Awarness utilizng educational resources
          and victim advocacy.' />
          <Feature 
          title='Intervention'
          text='Homeless Intervention and Mental Health Intervention'/>
          <Feature 
          title='Necessities'
          text='Provide for underserved communities and help provide a better quality of life.'/>
          <Feature 
          title='Empowerment'
          text="Youth and Women's Empowerment"/>
        </div>
      </div>
  )
}

export default WhatTVBF