import React from 'react'
import './footer.css'

const Footer = () => {
  return (
    <div className='tvbf__footer section__padding'>
      <div className='tvbf__footer-heading'>
        <h1>
          Building up the Body and Becoming more like Christ
        </h1>
      </div>
      <div className='tvbf__footer-btn'>
        <p>Get Connected Here!</p>
      </div>
      <div className='tvbf__footer-links'>
        <div className='tvbf__footer-links_logo'>
          {/* <img src={logo} /> */}
          <p>533 Avalon St # A, Lompoc, CA 93436</p>
        </div>
        <div className='tvbf__footer-links_div'>
          <h4>Links</h4>
          <p>Facbook</p>
          <p>Instagram</p>
          <p>Youtube</p>
          <p>Contact</p>
          </div>
        <div className='tvbf__footer-links_div'>
          <h4>Company</h4>
          <p>Terms & Conditions</p>
          <p>Privacy Policy</p>
          <p>Contact</p>
        </div>
        <div className='tvbf__footer-links_div'>
          <h4>Get in Touch</h4>
          <p>533 Avalon St # A, Lompoc, CA 93436</p>
          <p>(805) 736-1761</p>
          <p>tvbf@someemail.com</p>
        </div>
      </div>
      <div className='tvbf__footer-copyright'>
        <p>2022 TVBF. ALl right reserved.</p>
      </div>
    </div>
  )
}

export default Footer