import React from 'react'

import { Footer, Blog, Possibility, Features, WhatTVBF, Header } from './containers'
import { CTA, Brand, Navbar } from './components'
import './App.css'


const App = () => {
  return (
    <div className='App'>
      <div className='gradient__bg'>
        <Navbar />
        <Header />
      </div>
        <WhatTVBF />
        <Features />
        <Possibility />
        <Blog />
        <Footer />
    </div>
  )
}

export default App