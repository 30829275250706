import React from 'react'
import { Feature } from '../../components'
import './possibility.css'


const Possibility = () => {
  return (
    <div className='tvbf__possibility section__padding' id='possibility'>
      <div className='tvbf__possibility'>
      <div className='tvbf__possibility-heading'>
        <h1>
          Our Goals
        </h1>
      </div>
      <div className='tvbf__possibility-feature'>
        <Feature
        title="Goal #1"
        text="Some info about this goal and how close we are to it"
        />
        <Feature
        title="Goal #2"
        text="Some info about this goal and how close we are to it" />
      </div>
    </div>
    </div>
  )
}

export default Possibility